import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-image-list-preview")
@Component({
    selector: "s25-ng-image-list-preview",
    template: `
        <div class="image-list-preview-wrapper">
            <s25-popover
                [modelBean]="{ popoverTemplate: imagePreview }"
                [openTrigger]="'click'"
                [closeTrigger]="'click'"
            >
                <button class="aw-button aw-button--outline">View</button>
            </s25-popover>
            <s25-ng-image-copy-link [itemId]="imageId" [buttonStyle]="'text'"></s25-ng-image-copy-link>
        </div>

        <ng-template #imagePreview>
            <s25-ng-editable-image [val]="imageId" [readOnly]="true"></s25-ng-editable-image>
        </ng-template>
    `,
    styles: `
        .image-list-preview-wrapper {
            display: flex;
            gap: 5px;
            align-items: center;
        }

        ::ng-deep .c-textButton {
            font-weight: 600;
        }

        @media screen and (width < 530px) {
            .image-list-preview-wrapper {
                flex-direction: row-reverse;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25ImageListPreviewComponent {
    @Input({ required: true }) imageId: number;
}
