import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { S25TagsTableComponent } from "./s25.tags.table.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25EditMasterDefinitionTagComponent } from "./s25.edit.master.defs.tag.component";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25MasterDefinitionUsageReportComponent } from "./s25.master.defs.usage.report.component";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25TableModule } from "../s25-table/s25.table.module";
import { ModalEditTagComponent } from "./modal.edit.tag.component";
import { S25MdTagMultiSelectComponent } from "./s25.md.tag.picker.component";
import { S25MdTagItemCollapseComponent } from "./s25.md.tag.item.collapse.component";
import { S25MasterDefinitionsUsageComponent } from "./s25-master-definitions-usage/s25.master.definitions.usage.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25MasterDefinitionComponent } from "./s25.master.definition.component";
import { S25NotificationPolicyModule } from "../s25-notification-policy/s25.notification.policy.module";
import { S25CustomAttributeModule } from "../s25-custom-attribute/s25.custom.attribute.module";
import { S25ImageListComponent } from "./s25-image-list/s25.image.list.component";
import { S25ImageListPreviewComponent } from "./s25-image-list/s25.image.list.preview.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25ImageModule } from "../s25-image/s25.image.module";
import { S25OptModule } from "../s25-opt/s25.opt.module";
import { S25ImageFormModule } from "../s25-image-form/s25.image.form.module";

@NgModule({
    declarations: [
        S25TagsTableComponent,
        ModalEditTagComponent,
        S25MasterDefinitionUsageReportComponent,
        S25EditMasterDefinitionTagComponent,
        S25MdTagMultiSelectComponent,
        S25MasterDefinitionsUsageComponent,
        S25MdTagItemCollapseComponent,
        S25MasterDefinitionComponent,
        S25ImageListComponent,
        S25ImageListPreviewComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        S25CheckboxModule,
        S25SimpleCollapseModule,
        S25ToggleButtonModule,
        S25MultiselectModule,
        S25ModalModule,
        PopoverModule,
        S25TableModule,
        S25LoadingInlineModule,
        S25NotificationPolicyModule,
        S25CustomAttributeModule,
        S25EditableModule,
        S25ImageModule,
        S25OptModule,
        S25ImageFormModule,
    ],
    providers: [
        S25TagsTableComponent,
        S25EditMasterDefinitionTagComponent,
        ModalEditTagComponent,
        S25MdTagMultiSelectComponent,
        S25MasterDefinitionUsageReportComponent,
        S25MasterDefinitionsUsageComponent,
        S25MasterDefinitionComponent,
        S25ImageListComponent,
        S25ImageListPreviewComponent,
    ],
    exports: [
        S25TagsTableComponent,
        ModalEditTagComponent,
        S25MasterDefinitionUsageReportComponent,
        S25EditMasterDefinitionTagComponent,
        S25MdTagMultiSelectComponent,
        S25MasterDefinitionsUsageComponent,
        S25MasterDefinitionComponent,
        S25ImageListComponent,
        S25ImageListPreviewComponent,
    ],
})
export class S25MasterDefinitionsModule {
    constructor() {}
}
